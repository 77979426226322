@import '../../../styles/customMediaQueries.css';

.root {
}

.submitButton {
  padding: 0 0;
  margin-top: 24px;
  
  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    max-width: 360px;
    padding: 0;
    margin-top: 40px;
  }

  @media (max-width: 1023px) {
    max-width: 100%;
  }
}

.viewRequest {
  font-weight: var(--fontWeightSemiBold);
  font-size: var(--fontSizeRegular);
  line-height: 18px;
  color: var(--colorPrimary);
  border: var(--borderButton);
  border-radius: 4px;
}

.viewRequest:hover {
  box-shadow: none;
  border: var(--borderButtonHover);
  color: var(--colorSecondary);
}
