@import '../../../styles/customMediaQueries.css';

:global(.image-gallery-thumbnails-container) {
  text-align: left !important;
}
:global(.image-gallery-thumbnails) {
  padding-top: 24px;
  padding-bottom: 0;

  /*
   By default, the gallery controls the scroll position of the thumbnails when
   browsing the images. We change this logic to a freely scrollable container
   that the user controls. This overflow works together with the
   `disableThumbnailScroll` option in the component JS.
   */
  width: 100vw;
  overflow: auto;

  @media (--viewportMedium) {
    width: calc(100vw - 48px);
  }
  @media (--viewportLarge) {
    width: unset;
  }
}
:global(.image-gallery-thumbnail) {
  width: auto;
  border-radius: 8px;

  text-align: left !important;

  &:first-child {
    margin-left: 24px;

    @media (--viewportMedium) {
      margin-left: 0;
    }
  }
  &:last-child {
    margin-right: 24px;

    @media (--viewportMedium) {
      margin-right: 0;
    }
  }

  &:hover,
  &:global(.active) {
    border-color: var(--marketplaceColor);
  }
}

:global(.image-gallery-slide-wrapper) {
  background-color: var(--colorWhite);
  border-bottom: 1px solid var(--colorGrey100);

  @media (--viewportMedium) {
    border-radius: var(--borderRadiusMedium);
    border: 1px solid var(--colorGrey100);
    overflow: hidden;
  }
}

:global(.fullscreen) {
  background-color: var(--colorWhite) !important;

  & :global(.image-gallery-slide-wrapper) {
    background-color: transparent;
    border: none;
  }

  & :global(.image-gallery-thumbnails-container),
  & :global(.image-gallery-thumbnail) {
    text-align: center !important;
  }
}

.root {
}

.itemWrapper,
.itemWrapperFullscreen {
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemWrapper {
}

.noImage {
  border: 1px solid var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
}

.itemWrapperFullscreen {
  height: calc(100vh - 130px);
}

.itemCentering {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.item {
  max-width: 100%;
  max-height: 100%;
}

.thumb {
  /* With the active thumbnail border (default 4px), make the result
   height a multiple of the baseline. */
  max-width: 88px;
  max-height: 88px;
  border-radius: var(--borderRadiusMedium);
}

.navLeft,
.navRight {
  position: absolute;
  width: 60px;
  height: 100%;
  border: 0;
  padding: 0;
  z-index: 1;

  /* center content */
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;

    & .navArrowWrapper {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

.navLeft {
  left: 0;

  & .navArrowWrapper {
    padding-right: 2px;
  }
}

.navRight {
  right: 0;

  & .navArrowWrapper {
    padding-left: 2px;
  }
}

.navArrowWrapper {
  display: none;

  width: 40px;
  height: 40px;
  border-radius: 50%;

  background-color: rgba(255, 255, 255, 0.5);

  @media (--viewportLarge) {
    /* center content */
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.openFullscreen {
  /* Hide on mobile. Currently react-image-gallery fullscreen mode is broken on
     mobile, and mobile doesn't really benefit from fullscreen anyways. */
  display: none;

  composes: buttonSmall from global;

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;

  /* Ensure the button is on top of the navRight area */
  z-index: 1;

  @media (--viewportLarge) {
    display: block;
  }
}

.close {
  composes: marketplaceModalCloseStyles from global;

  color: var(--colorGrey300);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--colorBlack);
  }
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}

.modalGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.modalGridItem {
  position: relative;
  border-radius: 8px;
}

.modalGridItem img {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.closeModal {
  background: transparent;
  border: none;
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.galleryContainer {
  position: relative;
  padding-top: 16px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 12px;

  @media (max-width: 768px) {
    .grid {
      grid-template-columns: 1fr;
    }

    .gridItem {
      display: none;
    }

    .gridItem:first-child {
      display: block;
      grid-column: 1 / -1;
    }
  }
}

.gridThree {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 12px;

  .gridItem:first-child,
  .gridItem:nth-child(2),
  .gridItem:nth-child(3) {
    grid-column: span 2;
    grid-row: span 2;
  }

  @media (max-width: 768px) {
    .gridThree {
      grid-template-columns: 1fr;
    }

    .gridItem {
      display: none;
    }

    .gridItem:first-child {
      display: block;
      grid-column: 1 / -1;
    }
  }
}

.gridFour {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 12px;

  .gridItem:first-child {
    grid-column: span 2;
    grid-row: span 3;
  }

  .gridItem:nth-child(2) {
    grid-column: span 2;
    grid-row: span 2;
  }

  @media (max-width: 768px) {
    .gridFour {
      grid-template-columns: 1fr;
    }

    .gridItem {
      display: none;
    }

    .gridItem:first-child {
      display: block;
      grid-column: 1 / -1;
    }
  }
}

.gridItem {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
  border-radius: 8px;
}

.gridItem img:hover {
  transform: scale(1.05);
  border-radius: 8px;
}

.gridItem:first-child {
  grid-column: span 2;
  grid-row: span 2;
}

.viewAllButton {
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 1;
  background-color: var(--colorWhite);
  color: var(--colorPrimary);
}

.gridItem img {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.fullscreenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.fullscreenContent {
  position: relative;
  display: contents;
}

.fullscreenContent img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.closeFullscreen {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
}

.slideshowControls {
  margin-top: 15px;
  text-align: right;
}

.navButton {
  border: 1px solid var(--colorGrey100);
  border-radius: 4px;
  padding: 10px 18px 13px 18px;
  margin-left: 10px;
}

.navButton:hover {
  background-color: var(--colorBorder);
}